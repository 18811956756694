import React from 'react';
import { MaterialUICore } from '@iclinic/design-system';

import { image } from 'shared/utils/urlGetter';
import {
  BackgroundContainer,
  BackgroundDecorator,
  BackgroundImage,
  ImageContainer,
} from './SignupBackgroundImage.styles';

const { useTheme, useMediaQuery } = MaterialUICore;

const SignupBackgroundImage = () => {
  const theme = useTheme();
  const isBiggerThanLg = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <>
      <BackgroundContainer>
        <BackgroundImage />
      </BackgroundContainer>
      {isBiggerThanLg && (
        <ImageContainer>
          <BackgroundDecorator src={image('signup.decorator')} alt="" />
        </ImageContainer>
      )}
    </>
  );
};

export default SignupBackgroundImage;
