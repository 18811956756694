import {
  all,
  takeLatest,
  select,
  call,
  put,
  getContext,
  setContext,
} from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import {
  getCFMUpdateData,
  setData,
  submitProfessional,
  requestPending,
  professionalUpdatedSuccess,
  dataUpdateError,
  clinicUpdatedSuccess,
  submitClinic,
} from '.';
import {
  getClinicDetails,
  getProfessionalDetails,
  updateProfessional,
  updateClinic,
  getZipCodeSaga,
} from '../services';
import {
  Clinic,
  Professional,
  CFMUpdatePayload,
  ProfessionalFormValues,
  ClinicFormValues,
  UpdateClinicService,
} from '../types';
import { captureException } from 'shared/utils/handlerErrors';
import { isProfessionalIncomplete, isClinicIncomplete } from '../utils';
import { cboSelector } from './selectors';
import { CBO } from 'features/tuss/cbo/types';
import { listCBO } from 'features/tuss/cbo/state';
import { IStoreState } from 'state/rootReducer';
import USER_KIND from 'features/schedule/constants';

export function* getClinicData(clinicId: number) {
  try {
    const { data }: AxiosResponse<Clinic> = yield call(
      getClinicDetails,
      clinicId,
    );
    return data;
  } catch (error) {
    yield call(captureException, error);
  }

  return null;
}

export function* getProfessionalData(physicianId: string) {
  try {
    const { data }: AxiosResponse<Professional> = yield call(
      getProfessionalDetails,
      physicianId,
    );
    return data;
  } catch (error) {
    yield call(captureException, error);
  }

  return null;
}

function* getUserInfo() {
  const { clinicId, userData } = yield select(
    (state: IStoreState) => state.userInfo,
  );

  return { clinicId, ...userData };
}

export function* getDataSaga() {
  const {
    physicianId,
    userKind,
    isSubscriber,
    isClinicAdmin,
    isAdmin,
    clinicId,
  } = yield call(getUserInfo);

  if (!isSubscriber) return;

  const clinicCall =
    isAdmin || isClinicAdmin ? call(getClinicData, clinicId) : null;

  const professionalCall =
    userKind === USER_KIND.PHYSICIAN
      ? call(getProfessionalData, physicianId)
      : null;

  const [clinic, professional] = yield all([clinicCall, professionalCall]);

  const clinicNotHasCnpj =
    (!clinic?.clinic_cnpj && !clinic?.not_has_cnpj) ?? false;
  const professionalNotHasCnpj =
    (!professional?.professional_cnpj && !professional?.not_has_cnpj) ?? false;

  const clinicIncomplete = isClinicIncomplete(clinic);
  const professionalIncomplete = isProfessionalIncomplete(professional);

  const payload: CFMUpdatePayload = {
    clinic: clinicIncomplete || clinicNotHasCnpj ? clinic : null,
    professional:
      professionalIncomplete || professionalNotHasCnpj ? professional : null,
  };

  if (payload.professional) {
    yield put(listCBO());
  }

  if (payload.clinic || payload.professional) {
    yield put(setData(payload));
  }
}

export function* submitProfessionalWorker({
  payload,
}: {
  payload: ProfessionalFormValues;
}) {
  yield put(requestPending());

  const { physicianId } = yield call(getUserInfo);

  const cboList: CBO[] = yield select(cboSelector);
  const cboNumber =
    cboList.find((item) => item.term === payload.cbo)?.cbo || payload.cbo;

  const requestPayload = {
    ...payload,
    cbo: cboNumber,
    professional_cnpj: payload.not_has_cnpj ? '' : payload.professional_cnpj,
  };

  try {
    yield call(updateProfessional, physicianId, requestPayload);
    yield put(professionalUpdatedSuccess());
  } catch (error) {
    yield call(captureException, error);
    yield put(dataUpdateError(error.message));
  }
}

export function* submitClinicWorker({
  payload,
}: {
  payload: ClinicFormValues;
}) {
  yield put(requestPending());

  const { clinicId } = yield call(getUserInfo);

  const requestPayload = {
    ...payload,
    clinic_cnpj: payload.not_has_cnpj ? '' : payload.clinic_cnpj,
  };

  try {
    const updateClinicService: UpdateClinicService = yield getContext(
      'updateClinic',
    );

    yield call(updateClinicService, clinicId, requestPayload);
    yield put(clinicUpdatedSuccess());
  } catch (error) {
    yield put(dataUpdateError(error.message));
    yield call(captureException, error);
  }
}

export default function* cfmUpdateSagas() {
  yield setContext({ updateClinic });

  yield all([
    takeLatest(getCFMUpdateData, getDataSaga),
    takeLatest(submitProfessional, submitProfessionalWorker),
    takeLatest(submitClinic, submitClinicWorker),
    call(getZipCodeSaga),
  ]);
}
