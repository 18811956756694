import React, { useEffect } from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import SignupSuccess from './SuccessPage/Success';
import FormRoutes from './FormRoutes';
import { VariantProvider } from './SignupContext/SignupContext';
import { setIsVariant } from '../state/reducer';
import { urlVariantCampaign } from '../constants';

const StepSignupRoutes = ({ match }: RouteComponentProps) => {
  const dispatch = useDispatch();
  const isVariant = match.path.includes(`/${urlVariantCampaign}`);

  useEffect(() => {
    dispatch(setIsVariant(isVariant));
  }, [dispatch, isVariant]);
  return (
    <VariantProvider isVariant={isVariant}>
      <Switch>
        <Route
          exact
          path={`${match.path}/iniciar-teste`}
          component={SignupSuccess}
        />
        <Route component={FormRoutes} />
      </Switch>
    </VariantProvider>
  );
};

export default StepSignupRoutes;
