import { Store } from 'redux';

import * as appointmentGuide from 'features/tissInvoicing/state/appointmentGuide';
import * as appointmentGuideList from 'features/tissInvoicing/state/appointmentGuideList';
import * as stock from 'features/stock/state/actions';
import {
  setEvent,
  fetchSellerInfo,
  createBillOnSchedule,
} from 'features/onlinePayment/state/manage/actions';
import * as modal from 'state/modal/actions';
import * as modalTypes from 'state/modal/types';
import {
  SAVE_PATIENT_FLOWS,
  REMOVE_PATIENT,
} from 'state/marketing/emailFlow/patient/constants';
import * as records from 'state/records/actions';
import * as recordsTypes from 'state/records/types';
import * as memed from 'state/records/memed/actions';
import * as signature from 'state/records/signature/actions';
import {
  actions as textTemplateActions,
  actionTypes as textTemplateActionTypes,
} from 'state/records/textTemplate';
import {
  actions as lastEventTodayActions,
  actionTypes as lastEventTodayActionTypes,
} from 'features/feedDataLayer/state';
import * as imageComparison from 'state/records/attachments/imageComparison/actions';
import * as subscription from 'state/subscription/actions';
import * as upgrade from 'state/upgrade/actions';
import * as shared from 'state/shared/actions';
import * as onlineScheduleSettings from 'features/settings/scheduleOnline/state/actions';
import * as userInfo from 'state/userInfo/actions';
import * as upgradeAddon from 'state/upgradeAddon/actions';
import { setTokenManual } from 'features/new-auth/state/actions';
import { setPaymentData } from 'state/creditcard/actions';
import { openDetail as openDetailAddon } from 'state/billing/addon/actions';
import { openDetail as openDetailVideo } from 'state/video/actions';
import * as emailFlowRoot from 'state/marketing/emailFlow/root/actions';
import * as emailFlowPatient from 'state/marketing/emailFlow/patient/actions';
import * as memedTypes from 'state/records/memed/types';
import * as signatureTypes from 'state/records/signature/constants';
import * as scheduleTypes from 'state/schedule/constants';
import * as schedule from 'state/schedule/actions';
import { triggerDeleteMultiplePhysician } from 'features/settings/users/state/delete-multiple-physician';
import {
  TrackActivityClickEnum,
  trackUserActivityClick,
} from 'features/records/prescription/state';
import { setTotalPayingUsers } from 'features/subscription/upgradeCall/state/reducer';
import { setBillingInfo } from 'features/subscription/editPaymentDate/state/reducer';
import { fetchFinancialSellerData } from 'features/onlinePayment/state/panel';
import {
  trackIndicationToIntercom,
  updateRewardCongratulations,
  checkContactIsAPromoter,
} from 'features/referrals/state';
import { sendInteractionToHubspot } from 'features/onlinePayment/state/config/register';
import { setSchedulePatientData } from 'features/newFinance/state/patient';
import { setScheduleProceduresData } from 'features/newFinance/state/procedures';
import {
  closePostPaymentScheduleModal,
  resetPostPaymentScheduleState,
} from 'features/newFinance/state/reducer';
import { setPaymentConfig } from 'features/newFinance/state/payment';
import { fetchPatientsByEvents } from 'features/patientsByEvents/state';
import { actions as electronicSignatureActions } from 'features/records/electronicSignature/state';
import { actions as transcriptionDrawerActions } from 'features/records/transcriptionDrawer/state';
import { actions as calculatorDrawerActions } from 'features/records/calculatorDrawer/state';
import { actionTypes as cartPatientTypes } from 'features/records/cardPatient/state';
import { setPaymentSummaryData } from 'features/paymentSlip/state';
import {
  openConfirmationModal,
  openSuccessModal,
} from 'features/free-samples/state/opt-in-confirmation';

export const types = {
  modal: modalTypes,
  memed: memedTypes,
  marketing: {
    emailFlow: {
      patient: {
        SAVE_PATIENT_FLOWS,
        REMOVE_PATIENT,
      },
    },
  },
  signature: signatureTypes,
  scheduleTypes,
  prescription: { TrackActivityClickEnum },
  records: {
    ...recordsTypes,
    cardPatient: cartPatientTypes,
  },
  textTemplate: textTemplateActionTypes,
  closePostPaymentScheduleModal,
  lastEventToday: lastEventTodayActionTypes,
};

type AnyAction = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

export const createMapActionsToDispatch =
  (store: Store) =>
  (actions: AnyAction): AnyAction =>
    Object.keys(actions).reduce(
      (acc, name) => ({
        ...acc,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [name]: (...args: any) => store.dispatch(actions[name](...args)),
      }),
      {},
    );

export const getMappedActions = (store: Store) => {
  const mapActionsToDispatch = createMapActionsToDispatch(store);
  return {
    tissInvoicing: {
      appointmentGuide: mapActionsToDispatch(appointmentGuide.actions),
      appointmentGuideList: mapActionsToDispatch(appointmentGuideList.actions),
    },
    modal: mapActionsToDispatch(modal),
    records: {
      ...mapActionsToDispatch(records),
      electronicSignature: mapActionsToDispatch(electronicSignatureActions),
      transcriptionDrawer: mapActionsToDispatch(transcriptionDrawerActions),
      calculatorDrawer: mapActionsToDispatch(calculatorDrawerActions),
    },
    memed: mapActionsToDispatch(memed),
    signature: mapActionsToDispatch(signature),
    templatesRecords: mapActionsToDispatch(textTemplateActions),
    lastEventToday: mapActionsToDispatch(lastEventTodayActions),
    imageComparison: mapActionsToDispatch(imageComparison),
    subscription: mapActionsToDispatch(subscription),
    upgrade: mapActionsToDispatch(upgrade),
    auth: mapActionsToDispatch({ setTokenManual }),
    onlineScheduleSettings: mapActionsToDispatch(onlineScheduleSettings),
    creditcard: mapActionsToDispatch({ setPaymentData }),
    addon: mapActionsToDispatch({ openDetail: openDetailAddon }),
    video: mapActionsToDispatch({ openDetail: openDetailVideo }),
    stock: mapActionsToDispatch(stock),
    shared: mapActionsToDispatch(shared),
    userInfo: mapActionsToDispatch(userInfo),
    upgradeAddon: mapActionsToDispatch(upgradeAddon),
    marketing: {
      emailFlow: {
        patient: mapActionsToDispatch(emailFlowPatient),
        root: mapActionsToDispatch(emailFlowRoot),
      },
    },
    schedule: mapActionsToDispatch(schedule),
    onlinePayment: {
      manage: mapActionsToDispatch({
        setEvent,
        fetchSellerInfo,
        createBillOnSchedule,
      }),
      panel: mapActionsToDispatch({
        fetchFinancialSellerData,
      }),
      config: mapActionsToDispatch({
        sendInteractionToHubspot,
      }),
    },
    prescription: mapActionsToDispatch({ trackUserActivityClick }),
    subscriptionUpgradeCall: mapActionsToDispatch({ setTotalPayingUsers }),
    editPaymentDate: mapActionsToDispatch({ setBillingInfo }),
    settingsUsers: {
      deleteMultiplePhysician: mapActionsToDispatch({
        triggerDeleteMultiplePhysician,
      }),
    },
    referrals: mapActionsToDispatch({
      trackIndicationToIntercom,
      updateRewardCongratulations,
      checkContactIsAPromoter,
    }),
    newFinance: {
      setScheduleData: mapActionsToDispatch({
        setSchedulePatientData,
        setScheduleProceduresData,
        setPaymentConfig,
        resetPostPaymentScheduleState,
      }),
    },
    dashboard: mapActionsToDispatch({ fetchPatientsByEvents }),
    paymentSlip: mapActionsToDispatch({
      setPaymentSummaryData,
    }),
    freeSamples: mapActionsToDispatch({
      openConfirmationModal,
      openSuccessModal,
    }),
  };
};
