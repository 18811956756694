import { combineReducers } from 'redux';

import requestFreeSamples, { RequestFreeSamplesState } from './request-samples';
import sampleBoxes, { SampleBoxesState } from './sample-boxes';
import optInConfirmation, {
  OptInConfirmationState,
} from './opt-in-confirmation';

export type FreeSamplesState = {
  requestFreeSamples: RequestFreeSamplesState;
  sampleBoxes: SampleBoxesState;
  optInConfirmation: OptInConfirmationState;
};

const freeSamplesReducer = combineReducers<FreeSamplesState>({
  requestFreeSamples,
  sampleBoxes,
  optInConfirmation,
});

export default freeSamplesReducer;
