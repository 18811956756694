import { load as loadRecaptchaScript } from 'recaptcha-v3';
import { IReCaptchaLoaderOptions } from 'recaptcha-v3/dist/ReCaptchaLoader';

import iClinicApi from 'services/iclinic/api';
import { getCookie } from 'shared/utils/cookie';
import { SubscriptionData, UpdateHubspotData } from './types';

export const getUtmData = () => ({
  __utmzz: getCookie('__utmzz'),
  __utms: getCookie('__utms'),
});

export const verifyEmail = (email: string, captcha: string) =>
  iClinicApi.post<{ email: string }>('/accounts/email/validate/', {
    email,
    captcha,
    ...getUtmData(),
  });

export const updateContact = (hubspotInfo: UpdateHubspotData) =>
  iClinicApi.post('accounts/hubspot/update-by-steps/', {
    ...hubspotInfo,
    ...getUtmData(),
  });

export const submitSignup = (subscriptionData: SubscriptionData) =>
  iClinicApi.post<{ auto_login: string }>('/subscriptions/subscription/', {
    ...subscriptionData,
    create_auto_login: true,
    setup_step: 3,
    ...getUtmData(),
  });

export const fetchFilteredListCBO = (category: string) =>
  iClinicApi.get(`/tuss/cbocompare/?all&category=${category}`);

export const loadRecaptcha = (options?: IReCaptchaLoaderOptions | undefined) =>
  loadRecaptchaScript(process.env.GOOGLE_RECAPTCHA_V3_SITE_KEY ?? '', options);
