import { all, fork } from 'redux-saga/effects';

import requestFreeSamplesSagas from './request-samples/sagas';
import sampleBoxesSagas from './sample-boxes/sagas';
import optInSagas from './opt-in-confirmation/sagas';

export default function* freeSamplesSagas() {
  yield all([
    fork(requestFreeSamplesSagas),
    fork(sampleBoxesSagas),
    fork(optInSagas),
  ]);
}
