import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, useFormikContext, Form } from 'formik';
import { useRifm } from 'rifm';
import { Box, Button, MaterialUICore, Checkbox } from '@iclinic/design-system';

import * as S from './ClinicForm.styles';
import { clinicValidationSchema } from '../validation';
import useStyles from './dialogStyles.style';
import { close, submitClinic } from '../state';
import { ClinicFormValues } from '../types';
import {
  requestStatusDerivedSelector,
  clinicFormSelector,
} from '../state/selectors';
import { cepFormatter, cnpjFormatter } from 'shared/utils/formatters';
import { useGetZipCodeAddress } from './hooks';
import ErrorMessage from './ErrorMessage';
import ClinicFormFields from './ClinicFormFields';
import StateSelect from './StateSelect';
import CustomTextField from './CustomTextField';

const { CircularProgress } = MaterialUICore;

const submitButtonContent = (isLoading: boolean) =>
  isLoading ? <CircularProgress size={14} /> : 'Confirmar';

const errorMessage = (hasError: boolean) => hasError && <ErrorMessage />;

const ClinicForm = () => {
  const classes = useStyles();
  const {
    errors,
    touched,
    handleChange,
    handleBlur,
    values,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<ClinicFormValues>();

  const dispatch = useDispatch();
  const { isPending, hasError } = useSelector(requestStatusDerivedSelector);
  const { getAddress, zipCodeIsLoading } = useGetZipCodeAddress();

  const { value: zipCode, onChange: handleChangeZipCode } = useRifm({
    value: values.zip_code,
    onChange: (value: string) => {
      setFieldValue('zip_code', value);

      if (value.length === 9 && value !== values.zip_code) {
        getAddress(value);
      }
    },
    format: cepFormatter,
  });

  const { value: clinicCnpj, onChange: handleChangeCnpj } = useRifm({
    value: values.clinic_cnpj,
    onChange: (value: string) => {
      setFieldValue('clinic_cnpj', value);
    },
    format: cnpjFormatter,
  });

  useEffect(() => {
    if (values.zip_code.length === 9) {
      setFieldTouched('zip_code', true);
    }
  }, [values.zip_code, setFieldTouched]);

  const formDisabled = isPending || zipCodeIsLoading;

  const handleCnpjCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { checked } = event.target;
    setFieldValue('not_has_cnpj', checked);
  };

  return (
    <Form>
      <ClinicFormFields
        values={values}
        errors={errors}
        touched={touched}
        handleChange={handleChange}
        handleBlur={handleBlur}
        formDisabled={formDisabled}
        zipCode={zipCode}
        handleChangeZipCode={handleChangeZipCode}
        zipCodeIsLoading={zipCodeIsLoading}
        clinicCnpj={clinicCnpj}
        handleChangeCnpj={handleChangeCnpj}
      />
      <Box display="flex" justifyContent="space-between" mb={2}>
        <CustomTextField
          label="Cidade"
          id="city"
          name="city"
          type="text"
          placeholder="Ex: Ribeirão Preto"
          autoComplete="city"
          margin="normal"
          error={!!(touched.city && errors.city)}
          helperText={touched.city && errors.city}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.city}
          disabled={formDisabled}
          dataGa="cfmupdate-city"
          fullWidth
          className={classes.fieldMargin}
        />
        <StateSelect
          name="state"
          label="Estado"
          error={!!(touched.state && errors.state)}
          handleChange={handleChange}
          value={values.state}
          disabled={formDisabled}
          helperText={touched.state && errors.state}
        />
      </Box>

      <Checkbox
        name="not_has_cnpj"
        checked={values.not_has_cnpj}
        onChange={handleCnpjCheckboxChange}
        color="primary"
        label="A clínica não possui CNPJ"
      />

      {errorMessage(hasError)}
      <S.Box display="flex" justifyContent="flex-end">
        <S.Button
          color="transparent"
          className={classes.exitButton}
          onClick={() => dispatch(close())}
        >
          Cancelar
        </S.Button>
        <Button
          type="submit"
          disabled={formDisabled}
          className={classes.mainButton}
        >
          {submitButtonContent(formDisabled)}
        </Button>
      </S.Box>
    </Form>
  );
};

const ClinicFormContainer = () => {
  const dispatch = useDispatch();
  const clinic = useSelector(clinicFormSelector);

  return (
    <Formik
      enableReinitialize
      initialValues={clinic}
      onSubmit={(values) => {
        const updatedValues = {
          ...values,
          clinic_cnpj: values.not_has_cnpj ? '' : values.clinic_cnpj,
        };
        dispatch(submitClinic(updatedValues));
      }}
      validationSchema={clinicValidationSchema}
    >
      <ClinicForm />
    </Formik>
  );
};

export default ClinicFormContainer;
