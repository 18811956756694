import { RequestStatus } from 'shared/constants/State';
import { IStoreState } from 'state/rootReducer';

export const isModalOpen = ({ freeSamples }: IStoreState) =>
  freeSamples.optInConfirmation.isModalOpen;

export const isFetchingPharmas = ({ freeSamples }: IStoreState): boolean =>
  freeSamples.optInConfirmation.availablePharmas?.status ===
  RequestStatus.Pending;

export const getPharmas = ({ freeSamples }: IStoreState) =>
  freeSamples.optInConfirmation.availablePharmas?.pharmas ?? [];

export const getSelectedPharmas = ({ freeSamples }: IStoreState) =>
  freeSamples.optInConfirmation.selectedPharmas ?? [];

export const isOffCanvasOpen = ({ freeSamples }: IStoreState) =>
  freeSamples.optInConfirmation.isOffCanvasOpen;

export const isSuccessModalOpen = ({ freeSamples }: IStoreState) =>
  freeSamples.optInConfirmation.isSuccessModalOpen;

export const isOptInAccepted = ({ freeSamples }: IStoreState) =>
  freeSamples.optInConfirmation.status === RequestStatus.Success;
