import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, Box } from '@iclinic/design-system';

import { getCFMUpdateData, close } from '../state';
import StartStep from './StartStep';
import ClinicStep from './ClinicStep';
import ProfessionalStep from './ProfessionalStep';
import DoneStep from './DoneStep';
import { CFMUpdateSteps } from '../types';
import { stepSelector } from '../state/selectors';

const CFMUpdate = () => {
  const dispatch = useDispatch();
  const step = useSelector(stepSelector);

  const isOpen = ![CFMUpdateSteps.none, CFMUpdateSteps.close].includes(step);

  useEffect(() => {
    dispatch(getCFMUpdateData());
  }, [dispatch]);

  const handleClose = () => dispatch(close());

  const steps: Record<string, JSX.Element | null> = {
    start: <StartStep />,
    clinic: <ClinicStep />,
    professional: <ProfessionalStep />,
    done: <DoneStep onClose={handleClose} />,
  };
  steps.close = steps[step];

  return (
    <Dialog open={isOpen} disableEscapeKeyDown maxWidth="md">
      <Box width="560px">
        <Box mx={3} my={2}>
          {steps[step]}
        </Box>
      </Box>
    </Dialog>
  );
};

export default CFMUpdate;
