import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { Box, axiomTokens } from '@iclinic/design-system';

import PasswordCreationFields from 'shared/components/PasswordCreationFields';
import { passwordValidation } from '../../../../validationSchema';
import { passwordUIStateSelector } from '../../state/selectors';
import { SignupFormValues } from '../../state/types';
import { passwordFeedback } from './PasswordFeedback';
import { trackSignupStep } from '../../trackSignupUtils';

const { spacing } = axiomTokens;

const PasswordInput = () => {
  const { values } = useFormikContext<SignupFormValues>();
  const { disabled } = useSelector(passwordUIStateSelector);
  const requirements = passwordValidation(values.password);
  const [isFeedbackActive, toggleFeedback] = useState(false);

  const toggleFeedbackHandler = () => {
    toggleFeedback((currentState) => !currentState);
  };

  const onBlurHandler = useCallback(() => {
    trackSignupStep({
      name: 'user_password',
    });
    toggleFeedbackHandler();
  }, []);

  return (
    <Box mt={spacing[4]} position="relative">
      <PasswordCreationFields
        passwordLabel="Senha"
        passwordPlaceHolder="Ao menos 8 caracteres"
        requirements={requirements}
        disabled={disabled}
        customFeedback
        renderFeedback={passwordFeedback(isFeedbackActive)}
        onFocus={toggleFeedbackHandler}
        onBlur={onBlurHandler}
      />
    </Box>
  );
};

export default PasswordInput;
