import axios from 'axios';

import iClinicApi from 'services/iclinic/iClinicApi';
import { OptInResponse, PharmasResponse } from '../types';

export const iclinicApp = axios.create({
  baseURL: process.env.ICLINIC_APP_URL,
});

export const setFreeSamplesOptIn = (pharmas: number[]) =>
  iclinicApp.post<OptInResponse>('/amostras-gratis/request-waiting/', {
    pharmas,
  });

export const getPharmas = () =>
  iClinicApi.get<PharmasResponse>('/free-samples/pharmaceuticals/?limit=0');
