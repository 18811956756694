import {
  Button,
  Heading,
  IconButton,
  MaterialIcons,
} from '@iclinic/design-system';
import React, { useCallback, useState } from 'react';

import images from 'shared/paths/images';
import {
  BannerContainer,
  ButtonContainer,
  DescriptionContainer,
  ImageContainer,
  Image,
  HeadingContainer,
  ColorSpan,
  BoldSpan,
  Body,
} from './HyperaBanner.styles';

const HYPERA_BANNER_LOCAL_STORAGE_ID = 'hide-hypera-banner';

const { Close } = MaterialIcons;
export const HyperaBanner = () => {
  const [hideBanner, setHideBanner] = useState(
    window.localStorage.getItem(HYPERA_BANNER_LOCAL_STORAGE_ID) === 'true',
  );
  const handleClose = useCallback(() => {
    window.localStorage.setItem(HYPERA_BANNER_LOCAL_STORAGE_ID, 'true');
    setHideBanner(true);
  }, []);

  return hideBanner ? null : (
    <BannerContainer>
      <ImageContainer>
        <Image src={images.records.hyperaBannerBox} />
      </ImageContainer>
      <DescriptionContainer>
        <HeadingContainer>
          <Heading variant="lg">
            Amostras grátis direto no seu consultório
          </Heading>
          <IconButton
            label="Fechar"
            tooltip="left"
            size="sm"
            onClick={handleClose}
          >
            <Close />
          </IconButton>
        </HeadingContainer>
        <Body variant="xxs">
          O iClinic agora entrega{' '}
          <ColorSpan>amostras de medicamentos sem complicação</ColorSpan> via{' '}
          <BoldSpan>RX PRO</BoldSpan>, uma empresa do Grupo Afya. Ganhe acesso
          rápido e sem burocracia aos medicamentos que podem fazer a diferença
          no atendimento aos seus pacientes.
        </Body>
        <ButtonContainer>
          <Button size="small" variant="contained" color="primary">
            Quero receber amostras grátis
          </Button>
        </ButtonContainer>
      </DescriptionContainer>
    </BannerContainer>
  );
};
