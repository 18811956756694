import React from 'react';

import FormStepContainer from './FormStepContainer';
import ClinicForm from './ClinicForm';

const ClinicStep = () => (
  <FormStepContainer title="Atualização cadastral">
    <ClinicForm />
  </FormStepContainer>
);

export default ClinicStep;
