import {
  MaterialUICore,
  axiomTokens,
  Box as DSBox,
  Button as DSButton,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;

const { color, spacing } = axiomTokens;

export const Box = styled(DSBox)({
  gap: spacing[4],
});

export const Button = styled(DSButton)({
  color: color.interactive.accent.default,
});
