import React from 'react';

import ProfessionalFormContainer from './ProfessionalForm';
import FormStepContainer from './FormStepContainer';

const ProfessionalStep = () => (
  <FormStepContainer title="Atualização cadastral">
    <ProfessionalFormContainer />
  </FormStepContainer>
);

export default ProfessionalStep;
