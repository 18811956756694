import {
  Body as BodyDS,
  MaterialUICore,
  axiomTokens,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;

const { spacing, border, font } = axiomTokens;

export const BannerContainer = styled('div')(() => ({
  padding: spacing[4],
  display: 'flex',
  gap: spacing[4],
  background: 'linear-gradient(to right, #FFFFFF, #CFE5FF)',
  marginBottom: spacing[4],
  borderRadius: border.radius.md,
  height: '179px',
  overflow: 'hidden',
}));

export const ImageContainer = styled('div')(() => ({
  width: '231px',
  flex: 'none',
  position: 'relative',
}));

export const Image = styled('img')(() => ({
  top: '-50px',
  left: '-115px',
  position: 'absolute',
  height: 'auto',
  minWidth: '410px',
  transform: 'rotate(6deg)',
}));

export const HeadingContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const Body = styled(BodyDS)(() => ({
  lineHeight: font.lineHeight.md,
}));

export const ButtonContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'flex-end',
}));

export const DescriptionContainer = styled('div')(() => ({
  display: 'grid',
  gap: spacing[3],
}));

export const ColorSpan = styled('span')(() => ({
  fontWeight: font.weight.bold,
  color: '#d82777',
}));

export const BoldSpan = styled('span')(() => ({
  fontWeight: font.weight.bold,
}));
