/**
 * @deprecated
 * prefer routes served in webapp: /new/my-route
 * see: src/routes/Root.tsx
 */
import * as NewAuthFeatures from 'features/new-auth';
import OnlineScheduling from 'features/settings/scheduleOnline/routes';
import CreditCardEdit from 'features/subscription/containers/SubscriptionEdit';
import UpdatePaymentMessages from 'features/subscription/components/updatePaymentMessages';
import Video from 'features/video/containers/Video';
import Stock from 'features/stock/routes';
import EmailFlow from 'features/marketing/containers/EmailFlow';
import { TopNotification } from 'features/topNotification';
import {
  CampaignTopBar,
  SubscriptionBanner,
  DashboardBanner,
} from 'features/campaignManager';
import OnlinePaymentManage from 'features/onlinePayment/containers/manage';
import { ExportClinicDataManager } from 'features/exportClinicData';
import OnlinePaymentCheckout from 'routes/OnlinePayment/checkout';
import OnlinePaymentConfig from 'routes/OnlinePayment/config';
import MyIclinicPay from 'routes/OnlinePayment/my-iclinic-pay';
import PaymentsControlMedicalAgenda from 'features/onlinePayment/containers/PaymentsControlMedicalAgenda';
import { OnlinePaymentTransactions } from 'routes/OnlinePayment/reports';
import Telemedicine from 'features/video-conference/routes/Root';
import Campaign from 'routes/Marketing';
import UpgradeAddon from 'pages/upgradeAddon';
import Upgrade from 'pages/upgrade';
import SubscriptionProducts from 'pages/billing/subscriptionProducts';
import Subscription from 'pages/subscription';
import RecordDetails from 'pages/records/Details';
import FreeSamples from 'features/free-samples';
import {
  TissInvoicingSadt,
  TissInvoicingLot,
  TissInvoicingExpense,
  TissInvoicingGuideList,
  TissInvoicingAppointmentGuideList,
} from 'features/tissInvoicing';
import CFMUpdate from 'features/CFMUpdate/components/Container';
import ModalConfirmPhysiciansDelete from 'features/settings/users/containers/modal-confirm-physicians-delete';
import DownsellReason from 'features/settings/users/containers/downsell-reason';
import { KnowTheMedicalRecords, ScheduleButton } from 'features/schedule';
import PhysicianReferrals from 'features/referrals/containers/PhysicianReferrals';
import ReceptionistReferrals from 'features/referrals/containers/ReceptionistReferrals';
import { NavIcon as NavIconNotificationsIclinicPay } from 'features/onlinePayment/components/notifications-center';
import { NotificationsCenter as NotificationsCenterIclinicPay } from 'features/onlinePayment/containers/notifications-center';
import Checkout from 'features/checkout';
import SubscriptionUpgradeCall from 'features/subscription/upgradeCall/UpgradeCall';
import PaymentSlipLink from 'features/paymentSlipLink';
import { EditPaymentDate } from 'features/subscription/editPaymentDate';
import { TelemedicineReport } from 'features/reports/containers';
import { Panel } from 'features/onlinePayment/containers/panel';
import PatientsByEvents from 'features/patientsByEvents';
import AdditionalPlans from 'features/subscription/additionalPlans/migrations';
import PostPayment from 'features/newFinance/components/PostPayment';
import SurveyEntry from 'features/survey/routes/signedSurvey.routes';
import SurveySideBar from 'features/survey/components/SideBar';
import { BannerDataDownload } from 'features/banner-data-download';
import { DataMigrationBanner } from 'features/DataMigrationBanner';
import RewardsCongratulations from 'features/referrals/components/RewardsCongratulations';
import { useLastEventToday } from 'features/feedDataLayer';
import * as MessagingFeatures from 'features/messaging/signedEntry';
import * as Records from 'features/records';
import * as PaymentSlip from 'features/paymentSlip/containers';
import OnboardingPaywall from 'features/onboardingPaywall';
import OptInConfirmation from 'features/free-samples/containers/OptInConfirmation';

export default {
  ...NewAuthFeatures,
  RecordDetails,
  Subscription,
  SubscriptionProducts,
  Upgrade,
  Checkout,
  OnlineScheduling,
  CreditCardEdit,
  UpdatePaymentMessages,
  UpgradeAddon,
  Video,
  Stock,
  Marketing: {
    Campaign,
    EmailFlow,
  },
  ScheduleButton,
  Telemedicine,
  TopNotification,
  CampaignTopBar,
  SubscriptionBanner,
  OnlinePayment: {
    Checkout: OnlinePaymentCheckout,
    Config: OnlinePaymentConfig,
    MyIclinicPay,
    PaymentsControlMedicalAgenda,
    Reports: {
      Transactions: OnlinePaymentTransactions,
    },
    Manage: OnlinePaymentManage,
    NavIconNotificationsIclinicPay,
    NotificationsCenterIclinicPay,
    Panel,
  },
  ExportClinicDataManager,
  FreeSamples,
  Records,
  TissInvoicing: {
    Sadt: TissInvoicingSadt,
    Lot: TissInvoicingLot,
    Expense: TissInvoicingExpense,
    GuideList: TissInvoicingGuideList,
    AppointmentGuide: TissInvoicingAppointmentGuideList,
    AppointmentGuideList: TissInvoicingAppointmentGuideList,
  },
  CFMUpdate,
  Settings: {
    ModalConfirmPhysiciansDelete,
    DownsellReason,
  },
  Schedule: {
    KnowTheMedicalRecords,
  },
  DashboardBanner,
  InternReferrals: {
    Receptionist: ReceptionistReferrals,
    Physician: PhysicianReferrals,
  },
  SubscriptionUpgradeCall,
  PaymentSlipLink,
  EditPaymentDate,
  Reports: {
    TelemedicineReport,
  },
  AdditionalPlans,
  NewFinance: {
    PaymentFlow: PostPayment,
  },
  Survey: {
    PagesEntry: SurveyEntry,
    SurveySideBar,
  },
  BannerDataDownload,
  DataMigrationBanner,
  RewardsCongratulations,
  FeedDataLayer: {
    useLastEventToday,
  },
  PatientsByEvents,
  ...MessagingFeatures,
  PaymentSlip,
  OnboardingPaywall,
  OptIn: {
    OptInConfirmation,
  },
};
