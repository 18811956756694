import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  MaterialIcons,
} from '@iclinic/design-system';

import * as S from './styles';
import { isSuccessModalOpen } from 'features/free-samples/state/opt-in-confirmation/selectors';
import { closeSuccessModal } from 'features/free-samples/state/opt-in-confirmation';

const { Laugh } = MaterialIcons;

const ModalSuccess = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(isSuccessModalOpen);

  const handleCloseModal = () => {
    dispatch(closeSuccessModal());
  };

  return (
    <Dialog open={isOpen} onClose={handleCloseModal} maxWidth="sm">
      <DialogHeader
        onClose={handleCloseModal}
        title="Receber amostras grátis"
      />

      <DialogContent dividers>
        <S.Container>
          <S.IconWrapper>
            <Laugh />
          </S.IconWrapper>
          <S.Title variant="xs">Cadastro realizado com sucesso!</S.Title>
          <S.Subtitle variant="xxs">
            Em breve, você receberá um e-mail da RX PRO com mais detalhes sobre
            o envio de amostras grátis de medicamentos para o endereço
            cadastrado na iClinic.
          </S.Subtitle>
        </S.Container>
        <S.BodyDescription variant="xs">
          Você pode solicitar o cancelamento do serviço à qualquer momento via
          chat.
        </S.BodyDescription>
      </DialogContent>
    </Dialog>
  );
};

export default ModalSuccess;
