import React from 'react';
import { useSelector } from 'react-redux';

import {
  isModalOpen,
  isOffCanvasOpen,
  isSuccessModalOpen,
} from 'features/free-samples/state/opt-in-confirmation/selectors';
import {
  OptInConfirmationModal,
  PharmasOffCanvas,
  ModalSuccess,
} from '../../components/index';

const OptInConfirmation = () => {
  const isModalActive = useSelector(isModalOpen);
  const isOffCanvasActive = useSelector(isOffCanvasOpen);
  const isSuccessModalActive = useSelector(isSuccessModalOpen);

  return (
    <>
      {isModalActive && <OptInConfirmationModal />}
      {isOffCanvasActive && <PharmasOffCanvas />}
      {isSuccessModalActive && <ModalSuccess />}
    </>
  );
};

export default OptInConfirmation;
