import React from 'react';
import { Box, MaterialUICore } from '@iclinic/design-system';

import SignupBackgroundImage from './SignupBackgroundImage';
import { SingleSignupGrid } from './SignupContainer.styles';

const { useTheme, useMediaQuery } = MaterialUICore;

const StepSignupContainer = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box position="relative">
      {isMd && <SignupBackgroundImage />}
      <SingleSignupGrid>{children}</SingleSignupGrid>
    </Box>
  );
};

export default StepSignupContainer;
