import React, { ReactNode, useState } from 'react';
import { Checkbox } from '@iclinic/design-system';

import {
  TermsContainer,
  TermsTitle,
  TermsText,
  TermsAcceptArea,
  ContinueButton,
} from './TermsAI.styles';

export type TermsProps = {
  isLoading: boolean;
  sendAcceptTerms: (isAccepted: boolean) => void;
  children: ReactNode;
};

const TermsAI = ({ isLoading, sendAcceptTerms, children }: TermsProps) => {
  const [isAccepted, setIsAccepted] = useState(false);

  const handleAcceptTerms = () => setIsAccepted(!isAccepted);

  return (
    <TermsContainer data-testid="terms-component">
      <TermsTitle>Adendo Termo e Condições de Uso da iClinic</TermsTitle>
      <TermsText>{children}</TermsText>
      <TermsAcceptArea>
        <Checkbox
          checked={isAccepted}
          label="Concordo com os termos de uso"
          onChange={handleAcceptTerms}
        />
        <ContinueButton
          fullWidth
          isLoading={isLoading}
          disabled={!isAccepted}
          onClick={() => sendAcceptTerms(isAccepted)}
          data-ga="resumoai_term_continuar"
        >
          Continuar
        </ContinueButton>
      </TermsAcceptArea>
    </TermsContainer>
  );
};

export default TermsAI;
