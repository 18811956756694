import {
  Body as DSBody,
  axiomTokens,
  MaterialUICore,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;

const { font, color, spacing, border } = axiomTokens;

export const Container = styled('div')(() => ({
  backgroundColor: color.surface.feedback.success.subtle,
  textAlign: 'center',
  padding: spacing[4],
  borderRadius: border.radius.md,
  color: color.typeface.feedback.success,
}));

export const Title = styled(DSBody)(() => ({
  fontSize: font.size.md,
  fontWeight: font.weight.bold,
  lineHeight: '1.5',
  color: color.typeface.feedback.success,
  marginBottom: spacing[3],
}));

export const Subtitle = styled(DSBody)(() => ({
  fontSize: font.size.xs,
  lineHeight: '1.5',
  color: color.typeface.feedback.success,
}));

export const BodyDescription = styled(DSBody)(() => ({
  textAlign: 'center',
  fontSize: font.size.xxs,
  backgroundColor: color.surface.accent.default,
  padding: `${spacing[1]} ${spacing[2]}`,
  borderRadius: border.radius.md,
}));

export const IconWrapper = styled('div')({
  textAlign: 'center',
  height: spacing[8],
  width: '100%',
  marginBottom: spacing[3],

  '& svg': {
    width: '100%',
    height: '100%',
    fill: 'currentColor',
  },
});
