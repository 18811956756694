import React from 'react';
import { TextField } from '@iclinic/design-system';

type CustomFieldProps = {
  label: string;
  id: string;
  name: string;
  placeholder: string;
  autoComplete: string;
  error: boolean;
  helperText?: string | false;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  disabled: boolean;
  InputProps?: object;
  dataGa: string;
  fullWidth?: boolean;
  className?: string;
  type?: string;
  margin?: 'none' | 'dense' | 'normal';
};

const CustomTextField: React.FC<CustomFieldProps> = ({
  label,
  id,
  name,
  placeholder,
  autoComplete,
  error,
  helperText,
  value,
  onChange,
  onBlur,
  disabled,
  InputProps,
  dataGa,
  fullWidth = false,
  className = '',
  type,
  margin,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e);
  };
  return (
    <TextField
      label={label}
      id={id}
      name={name}
      type={type}
      placeholder={placeholder}
      autoComplete={autoComplete}
      error={error}
      helperText={helperText}
      onChange={handleChange}
      onBlur={onBlur}
      value={value}
      disabled={disabled}
      InputProps={InputProps}
      data-ga={dataGa}
      fullWidth={fullWidth}
      className={className}
      margin={margin}
    />
  );
};

export default CustomTextField;
