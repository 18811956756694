import { createSelector } from '@reduxjs/toolkit';

import { IStoreState } from 'state/rootReducer';
import { cepFormatter } from 'shared/utils/formatters';
import { RequestStatus } from 'shared/constants/State';

export const requestStatusSelector = (state: IStoreState) =>
  state.cfmUpdate.main.status;
export const professionalSelector = (state: IStoreState) =>
  state.cfmUpdate.main.professional;
export const cboSelector = (state: IStoreState) => state.cbo.listCBO.objects;
export const clinicSelector = (state: IStoreState) =>
  state.cfmUpdate.main.clinic;

export const professionalFormSelector = createSelector(
  professionalSelector,
  cboSelector,
  (professional, cboList) => {
    const cpf = professional?.profile?.cpf || '';
    const rqe = professional?.rqe || '';
    const council_name = professional?.council_name || '';
    const council_number = professional?.council_number || '';
    const council_region = professional?.council_region || '';

    const foundCbo = cboList.find((value) => professional?.cbo === value.cbo);
    const cbo = foundCbo?.term || '';

    const professional_cnpj = professional?.profile.professional_cnpj ?? '';
    const not_has_cnpj = professional?.profile.not_has_cnpj ?? false;

    return {
      cpf,
      rqe,
      cbo,
      council_name,
      council_number,
      council_region,
      professional_cnpj,
      not_has_cnpj,
    };
  },
);

export const cboInputSelector = createSelector(cboSelector, (cboList) => [
  { active: false, term: '', id: 0, cbo: 0 },
  ...cboList,
]);

export const clinicFormSelector = createSelector(clinicSelector, (clinic) => ({
  zip_code: cepFormatter(clinic?.zip_code || ''),
  address: clinic?.address || '',
  number: clinic?.number?.toString() || '',
  complement: clinic?.complement || '',
  city: clinic?.city || '',
  state: clinic?.state || '',
  neighborhood: clinic?.neighborhood || '',
  clinic_cnpj: clinic?.clinic_cnpj ?? '',
  not_has_cnpj: clinic?.not_has_cnpj ?? false,
}));

export const stepSelector = (state: IStoreState) => state.cfmUpdate.main.step;

export const requestStatusDerivedSelector = createSelector(
  requestStatusSelector,
  (status) => ({
    requestStatus: status,
    isPending: status === RequestStatus.Pending,
    hasError: status === RequestStatus.Error,
  }),
);
