import React, { ReactNode } from 'react';
import { Link, MaterialIcons } from '@iclinic/design-system';

import {
  StyledDrawer,
  Header,
  Content,
  BackArea,
  BackButton,
} from './SidebarAI.styles';

const { ArrowBack } = MaterialIcons;

export type SidebarProps = {
  title: string;
  isOpen: boolean;
  dataGA?: string;
  onClickBack: () => void;
  children: ReactNode;
};

const SidebarAI = ({
  title,
  isOpen,
  dataGA,
  onClickBack,
  children,
}: SidebarProps) => (
  <StyledDrawer anchor="right" open={isOpen} data-testid="Sidebar">
    <Header>
      <h2>{title}</h2>
      <Link
        href="https://suporte.iclinic.com.br/pt-br/otimiza%C3%A7%C3%A3o-prontu%C3%A1rio-adendo-termo-e-condi%C3%A7%C3%B5es"
        target="_blank"
        rel="noreferrer"
      >
        Termos de uso
      </Link>
    </Header>
    <Content>{children}</Content>
    <BackArea>
      <BackButton
        size="medium"
        variant="contained"
        color="transparent"
        startIcon={<ArrowBack />}
        onClick={onClickBack}
        data-ga={dataGA}
      >
        Voltar
      </BackButton>
    </BackArea>
  </StyledDrawer>
);

export default SidebarAI;
