import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Body,
  Box,
  Button,
  Logo,
  MaterialIcons,
  MaterialUICore,
  tokens,
} from '@iclinic/design-system';

import history from 'routes/history';
import { triggerSubmitAutoLogin } from '../../state/reducer';
import { autoLoginSelector, emailSelector } from '../../state/selectors';
import * as S from './Success.styles';

const ListIcon = ({ delay = 1000 }: { delay?: number }) => {
  const { CircularProgress } = MaterialUICore;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, delay);

    return () => clearTimeout(timer);
  }, [delay]);

  return loading ? (
    <CircularProgress size={14} />
  ) : (
    <S.ListIconContainer>
      <S.ListCheck fontSize="inherit" />
    </S.ListIconContainer>
  );
};

const SignupSuccess = () => {
  const dispatch = useDispatch();

  const hasAutoLogin = useSelector(autoLoginSelector);
  const email = useSelector(emailSelector);

  useEffect(() => {
    if (!hasAutoLogin) history.push('/new/cadastro/');
  }, [hasAutoLogin]);

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(triggerSubmitAutoLogin());
    }, 5000);

    return () => clearTimeout(timer);
  });

  const handleLogin = () => {
    dispatch(triggerSubmitAutoLogin());
  };

  return (
    <S.SuccessContainer>
      <S.SuccessBackground />
      <Box mx="auto" textAlign="center" px={tokens.spacing.md}>
        <Box mb={tokens.spacing.xl}>
          <Logo width={196} />
        </Box>
        <Box mb={tokens.spacing.md}>
          <S.SuccessHeading variant="sm">Tudo certo!</S.SuccessHeading>
        </Box>
        <Box mb={tokens.spacing.xxl}>
          <S.SuccessList>
            <S.SuccessItem>
              <ListIcon />
              <Body variant="xs" inverseTextColor>
                Seu e-mail de acesso é <strong>{email}</strong>
              </Body>
            </S.SuccessItem>
            <S.SuccessItem>
              <ListIcon delay={2000} />
              <Body variant="xs" inverseTextColor>
                Sua <strong>senha</strong> foi considerada segura
              </Body>
            </S.SuccessItem>
            <S.SuccessItem>
              <ListIcon delay={4000} />
              <Body variant="xs" inverseTextColor>
                <strong>Sua conta gratuita por 5 dias</strong> foi criada no
                sistema
              </Body>
            </S.SuccessItem>
          </S.SuccessList>
        </Box>
        <S.SucessTextContainer>
          <Box mb={tokens.spacing.md}>
            <S.SuccessBody variant="xs" align="center">
              Aproveite ao máximo nosso sistema! Caso tenha qualquer dúvida,
              chame nossos especialistas pelo chat dentro do sistema, disponível
              no botão no canto inferior direito.
            </S.SuccessBody>
          </Box>
          <Box mb={tokens.spacing.md}>
            <S.SuccessBody variant="xs" align="center">
              Clique no botão e bom teste
            </S.SuccessBody>
          </Box>
        </S.SucessTextContainer>
        <Button
          data-testid="init-test-button"
          onClick={handleLogin}
          startIcon={<MaterialIcons.ArrowForward />}
        >
          <Body bold variant="xs" inverseTextColor>
            Iniciar teste
          </Body>
        </Button>
      </Box>
    </S.SuccessContainer>
  );
};

export default SignupSuccess;
