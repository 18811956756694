// External
import { all, spawn } from 'redux-saga/effects';

// Internal
import cfmUpdateSagas from 'features/CFMUpdate/state/sagas';
import checkoutSagas from 'features/checkout/state/sagas';
import exportDataSagas from 'features/exportClinicData/state/sagas';
import freeSamplesSagas from 'features/free-samples/state/sagas';
import marketingReferralsSagas from 'features/marketing/state/sagas';
import newPasswordSaga from 'features/new-auth/new-password/state/sagas';
import resetPasswordRequestSaga from 'features/new-auth/reset-password/state/sagas';
import auth2Sagas from 'features/new-auth/state/sagas';
import newFinanceSagas from 'features/newFinance/state/sagas';
import onlinePaymentSagas from 'features/onlinePayment/state/sagas';
import patientsByEventsSagas from 'features/patientsByEvents/state/sagas';
import paymentSlipLinkSagas from 'features/paymentSlipLink/state/sagas';
import prescriptionSaga from 'features/records/prescription/state/sagas';
import referralsSagas from 'features/referrals/state/sagas';
import reportsSagas from 'features/reports/state/sagas';
import settingsOnlineSagas from 'features/settings/scheduleOnline/state/sagas';
import settingsUsersSagas from 'features/settings/users/state/sagas';
import stockSagas from 'features/stock/state/sagas';
import editPaymentDateSagas from 'features/subscription/editPaymentDate/state/sagas';
import upgradeCallSagas from 'features/subscription/upgradeCall/state/sagas';
import surveySagas from 'features/survey/state/sagas';
import sadtSagas from 'features/tissInvoicing/state/sagas';
import cboSagas from 'features/tuss/cbo/state/sagas';
import billingSagas from 'state/billing/sagas';
import campaignSagas from 'state/campaign/sagas';
import campaignManagerSagas from 'state/campaignManager/sagas';
import creditcardSagas from 'state/creditcard/sagas';
import googleSagas from 'state/google/sagas';
import emailFlowSagas from 'state/marketing/emailFlow/sagas';
import marketingUnsubscribeSagas from 'state/marketing/unsubscribe/sagas';
import recordSagas from 'state/records/sagas';
import templateSagas from 'state/records/textTemplate/sagas';
import scheduleSagas from 'state/schedule/sagas';
import { keepAliveSaga, sharedSagas } from 'state/shared/sagas';
import subscriptionSagas from 'state/subscription/sagas';
import upgradeSagas from 'state/upgrade/sagas';
import upgradeAddonSagas from 'state/upgradeAddon/sagas';
import userInfoSagas from 'state/userInfo/sagas';
import messagingSagas from 'features/messaging/state/sagas';
import electronicSignatureSagas from 'features/records/electronicSignature/state/sagas';
import stepSignupSagas from 'features/new-auth/signup/stepSignup/state/sagas';
import paymentSlipSagas from 'features/paymentSlip/state/sagas';

export default function* rootSaga() {
  const sagas = [
    auth2Sagas,
    billingSagas,
    campaignSagas,
    campaignManagerSagas,
    googleSagas,
    recordSagas,
    subscriptionSagas,
    upgradeSagas,
    sharedSagas,
    settingsOnlineSagas,
    creditcardSagas,
    stockSagas,
    marketingUnsubscribeSagas,
    upgradeAddonSagas,
    userInfoSagas,
    emailFlowSagas,
    scheduleSagas,
    onlinePaymentSagas,
    resetPasswordRequestSaga,
    newPasswordSaga,
    prescriptionSaga,
    exportDataSagas,
    marketingReferralsSagas,
    cboSagas,
    freeSamplesSagas,
    sadtSagas,
    cfmUpdateSagas,
    settingsUsersSagas,
    checkoutSagas,
    referralsSagas,
    upgradeCallSagas,
    paymentSlipLinkSagas,
    templateSagas,
    editPaymentDateSagas,
    reportsSagas,
    surveySagas,
    newFinanceSagas,
    patientsByEventsSagas,
    messagingSagas,
    electronicSignatureSagas,
    stepSignupSagas,
    paymentSlipSagas,
  ];

  yield all(sagas.map((saga) => spawn(keepAliveSaga, saga)));
}
