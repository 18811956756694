/* eslint-disable no-unneeded-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable  sonarjs/prefer-single-boolean-return */
/* eslint-disable sonarjs/cognitive-complexity */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  DatePicker,
  Loader,
  MaterialIcons,
  MaterialUICore,
  Typography,
} from '@iclinic/design-system';

import useStyles from './index.styles';
import { setExportDrawer } from '../../state/exportHome';
import { fetchExportList } from '../../state/exportList';
import {
  ExportPhysician,
  ExportResource,
} from 'features/exportClinicData/state/exportRequest/types';
import {
  changeSelectedAllPhysicians,
  changeSelectedAllResources,
  changeSelectedFormat,
  changeSelectedPeriod,
  changeSelectedStartPeriod,
  changeSelectedEndPeriod,
  fetchClinicPhysicians,
  togglePhysicianCheckbox,
  toggleResourceCheckbox,
  changeSelectedPhysicians,
  sendNewExportRequest,
  setDuplicatedExport,
  changeSelectedFilterPatientsByPhysicians,
} from 'features/exportClinicData/state/exportRequest';
import {
  getClinicPhysicians,
  getNewRequestStatus,
  getSelectedFormat,
  getSelectedAllPhysicians,
  getSelectedAllResources,
  getSelectedResources,
  getSelectedPeriod,
  getSelectedStartPeriod,
  getSelectedEndPeriod,
  getSelectedPhysicians,
  getDuplicatedRequest,
  getSelectedFilterPatientsByPhysicians,
} from 'features/exportClinicData/state/exportRequest/selectors';
import { RequestStatus } from 'shared/constants/State';
import { exportHasValidDates } from 'features/exportClinicData/components/exportRequest/utils';
import { getUserInfo } from 'state/records/selectors';
import SUBSCRIPTION_PERMISSIONS from 'shared/constants/subscriptionPermissions';

const { ReportProblemOutlined } = MaterialIcons;
const { FormControlLabel, Radio, RadioGroup } = MaterialUICore;

export default function ExportRequest(): JSX.Element {
  const dateObjectToString = (dateObj: Date) =>
    `${dateObj.getFullYear()}-${dateObj.getMonth() + 1}-${dateObj.getDate()}`;

  const today = new Date();
  const past30days = new Date().setDate(today.getDate() - 30);
  const past = new Date(past30days);
  const initialStartDate = dateObjectToString(past);
  const initialEndDate = dateObjectToString(today);

  const dispatch = useDispatch();

  const userInfoClinicId = useSelector((state) => state.userInfo.clinicId);
  const userInfoEmail = useSelector(
    (state) => state.userInfo.userData.userEmail,
  );
  const userInfoSubscriptionId = useSelector(
    (state) => state.userInfo.userData.subscriptionId,
  );

  const ids = {
    subscriptionId: userInfoSubscriptionId,
    clinicId: userInfoClinicId,
  };

  useEffect(() => {
    dispatch(fetchClinicPhysicians(ids));
  }, []);

  const clinicList = useSelector(getClinicPhysicians);
  const selectedPhysicians = useSelector(getSelectedPhysicians);
  const allPhysicians = useSelector(getSelectedAllPhysicians);
  const resources = useSelector(getSelectedResources);
  const allResources = useSelector(getSelectedAllResources);
  const selectedFileFormat = useSelector(getSelectedFormat);
  const selectedPeriod = useSelector(getSelectedPeriod);
  const selectedStartPeriod = useSelector(getSelectedStartPeriod);
  const selectedEndPeriod = useSelector(getSelectedEndPeriod);
  const duplicatedRequest = useSelector(getDuplicatedRequest);
  const newRequestStatus = useSelector(getNewRequestStatus);
  const selectedFilterPatientsByPhysicians = useSelector(
    getSelectedFilterPatientsByPhysicians,
  );
  const { permissions } = useSelector(getUserInfo);

  if (!selectedPhysicians && clinicList && clinicList.length > 0) {
    const sortedPhysicians = [...clinicList[0].physicians].sort((a, b) =>
      a.name > b.name ? 1 : -1,
    );
    const preSelectedPhysicians = sortedPhysicians.map((prePhysician) => ({
      ...prePhysician,
      checked: false,
    }));
    dispatch(changeSelectedPhysicians(preSelectedPhysicians));
  }

  if (selectedStartPeriod === null) {
    dispatch(changeSelectedStartPeriod(initialStartDate));
  }

  if (selectedEndPeriod === null) {
    dispatch(changeSelectedEndPeriod(initialEndDate));
  }

  const handleAllPhysiciansCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    dispatch(changeSelectedAllPhysicians(event.target.checked));
    const newPs = selectedPhysicians?.map((ps) => ({
      ...ps,
      checked: event.target.checked,
    }));
    dispatch(togglePhysicianCheckbox(newPs));
    dispatch(setDuplicatedExport(false));
  };

  const checkForAllPhysicians = (physicians: ExportPhysician[] | undefined) =>
    physicians?.reduce(
      (result, physician) => physician.checked && result,
      true,
    ) || false;

  const handleSelectedFilterPatientsByPhysiciansChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    dispatch(changeSelectedFilterPatientsByPhysicians(event.target.checked));
    dispatch(setDuplicatedExport(false));
  };

  const handlePhysicianCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newPs = selectedPhysicians?.map((selectedPhysician) =>
      selectedPhysician.name === event.target.name
        ? { ...selectedPhysician, checked: event.target.checked }
        : selectedPhysician,
    );
    dispatch(togglePhysicianCheckbox(newPs));
    const allPhysicians = checkForAllPhysicians(newPs);
    dispatch(changeSelectedAllPhysicians(allPhysicians));
    dispatch(setDuplicatedExport(false));
  };

  const handleAllResourceCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    dispatch(changeSelectedAllResources(event.target.checked));
    const newResources = resources?.map((resource) => ({
      ...resource,
      checked: event.target.checked,
    }));
    dispatch(toggleResourceCheckbox(newResources));
    dispatch(setDuplicatedExport(false));
  };

  const checkForAllResources = (resources: ExportResource[] | undefined) =>
    resources?.reduce((result, resource) => resource.checked && result, true) ||
    false;

  const handleResourceCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newResources = resources?.map((resource) =>
      resource.name === event.target.name
        ? { ...resource, checked: event.target.checked }
        : resource,
    );
    dispatch(toggleResourceCheckbox(newResources));
    const allResources = checkForAllResources(newResources);
    dispatch(changeSelectedAllResources(allResources));
    dispatch(setDuplicatedExport(false));
  };

  const handlePeriodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeSelectedPeriod(event.target.value));
    if (event.target.value === '30') {
      dispatch(changeSelectedStartPeriod(initialStartDate));
      dispatch(changeSelectedEndPeriod(initialEndDate));
    }
    dispatch(setDuplicatedExport(false));
  };

  const handleStartPeriodChange = (
    dateObject: React.ChangeEvent<HTMLInputElement>,
  ) => {
    dispatch(changeSelectedStartPeriod(dateObjectToString(dateObject)));
    dispatch(changeSelectedPeriod('custom'));
    dispatch(setDuplicatedExport(false));
  };

  const handleEndPeriodChange = (
    dateObject: React.ChangeEvent<HTMLInputElement>,
  ) => {
    dispatch(changeSelectedEndPeriod(dateObjectToString(dateObject)));
    dispatch(changeSelectedPeriod('custom'));
    dispatch(setDuplicatedExport(false));
  };

  const handleFileFormatChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    dispatch(changeSelectedFormat(event.target.value));
    dispatch(setDuplicatedExport(false));

    if (event.target.value === 'pdf') {
      dispatch(changeSelectedPeriod('all'));
      dispatch(changeSelectedAllResources(true));
      const newResources = resources?.map((resource) => ({
        ...resource,
        checked: true,
      }));
      dispatch(toggleResourceCheckbox(newResources));
    }
  };

  const exportHasPhysician = () => {
    const selectedPs: boolean[] | undefined = selectedPhysicians?.map(
      (physician) => physician.checked,
    );
    if (selectedPs?.includes(true)) {
      return true;
    }
    return false;
  };

  const exportHasResource = () => {
    const selectedRs: boolean[] | undefined = resources?.map(
      (resource) => resource.checked,
    );
    if (selectedRs?.includes(true)) {
      return true;
    }
    return false;
  };

  const exportIsValid = () => {
    if (
      exportHasPhysician() &&
      exportHasValidDates(
        selectedPeriod,
        selectedStartPeriod,
        selectedEndPeriod,
      ) &&
      exportHasResource() &&
      !duplicatedRequest &&
      newRequestStatus !== RequestStatus.Pending
    ) {
      return true;
    }
    return false;
  };

  const requestNewDataExport = () => {
    const resourcesToExport = resources?.map(
      ({ checked, name }) => checked && name,
    );
    const selectedResourcesOnly = resourcesToExport?.filter(
      (item) => item !== false,
    );
    const physiciansToExport = selectedPhysicians?.map(
      ({ profile_id, checked, ...psAttr }) => checked && psAttr,
    );
    const selectedPhysiciansOnly = physiciansToExport?.filter(
      (item) => item !== false,
    );
    const emailToNotify: string[] = [userInfoEmail];
    const exportData = {
      physicians: selectedPhysiciansOnly,
      resources: selectedResourcesOnly,
      subscription_id: userInfoSubscriptionId,
      clinic_id: clinicList[0].clinic_id,
      clinic_name: clinicList[0].name,
      file_type: selectedFileFormat,
      emails: emailToNotify,
      all_physicians: allPhysicians,
      filter_physicians: selectedFilterPatientsByPhysicians,
      status: selectedFileFormat === 'pdf' ? 'scheduled' : undefined,
    };
    const requestData =
      selectedPeriod === 'all'
        ? exportData
        : {
            ...exportData,
            date_start: selectedStartPeriod,
            date_end: selectedEndPeriod,
          };
    dispatch(sendNewExportRequest(requestData));
    dispatch(fetchExportList());
  };

  const classes = useStyles();
  const hasPdfPermission = permissions?.includes(
    SUBSCRIPTION_PERMISSIONS.ExportPdf,
  );
  const isPdf = selectedFileFormat === 'pdf';

  return (
    <Box className={classes.mainBox}>
      <Box className={classes.formBox}>
        <Typography variant="h3" className={classes.drawerTitle}>
          Nova exportação
        </Typography>
        <Box>
          <Box>
            <Typography variant="body2" className={classes.fieldName}>
              Clínica
            </Typography>
            <Typography variant="body1" className={classes.fieldValue}>
              {!!clinicList && clinicList[0].name}
            </Typography>
          </Box>
          <Typography variant="body2" className={classes.fieldName}>
            Profissionais de saúde
          </Typography>
          <Box>
            <Checkbox
              label="Todos"
              data-testid="allPhysicians"
              checked={allPhysicians}
              name="Todos"
              onChange={handleAllPhysiciansCheckboxChange}
            />
          </Box>
          <Box className={classes.physiciansList}>
            {selectedPhysicians?.map((selectedPhysician) => (
              <Checkbox
                key={selectedPhysician.physician_id}
                label={selectedPhysician.name}
                checked={selectedPhysician.checked}
                name={selectedPhysician.name}
                onChange={handlePhysicianCheckboxChange}
              />
            ))}
          </Box>
        </Box>
        <Box>
          <Typography variant="body2" className={classes.fieldName}>
            Período
          </Typography>
          <RadioGroup
            className={classes.inlineRadioGroup}
            value={selectedPeriod}
            onChange={handlePeriodChange}
          >
            <FormControlLabel
              value="all"
              control={<Radio color="primary" />}
              label="Tudo"
              className={classes.inlineRadio}
              disabled={isPdf}
            />
            <FormControlLabel
              value="30"
              control={<Radio color="primary" />}
              label="Últimos 30 dias"
              className={classes.inlineRadio}
              disabled={isPdf}
            />
            <FormControlLabel
              value="custom"
              control={<Radio color="primary" />}
              label="Entre as datas:"
              className={classes.inlineRadio}
              disabled={isPdf}
            />
          </RadioGroup>
          <Box>
            <DatePicker
              disabled={selectedPeriod === 'custom' ? false : true}
              label="A partir de"
              className={classes.datePicker}
              value={selectedStartPeriod}
              onChange={handleStartPeriodChange}
            />
            <DatePicker
              disabled={selectedPeriod === 'custom' ? false : true}
              label="Até"
              className={classes.datePicker}
              value={selectedEndPeriod}
              onChange={handleEndPeriodChange}
            />
          </Box>
        </Box>
        <Box>
          <Typography variant="body2" className={classes.fieldName}>
            Formato do arquivo
          </Typography>
          <RadioGroup
            className={classes.inlineRadioGroup}
            value={selectedFileFormat}
            onChange={handleFileFormatChange}
          >
            <FormControlLabel
              value="csv"
              control={<Radio color="primary" />}
              label="CSV"
              className={classes.inlineRadio}
            />
            <FormControlLabel
              value="xlsx"
              control={<Radio color="primary" />}
              label="XLSX"
              className={classes.inlineRadio}
            />
            {hasPdfPermission ? (
              <FormControlLabel
                value="pdf"
                control={<Radio color="primary" />}
                label="PDF"
                className={classes.inlineRadio}
              />
            ) : null}
          </RadioGroup>
        </Box>

        <Box>
          <Typography variant="body2" className={classes.fieldName}>
            Dados da exportação
          </Typography>
          <Box>
            <Checkbox
              label="Todos os dados"
              data-testid="allResources"
              checked={allResources}
              name="Todos"
              onChange={handleAllResourceCheckboxChange}
              disabled={isPdf}
            />
          </Box>
          <Box>
            {resources?.map((resource) => (
              <Checkbox
                key={resource.name}
                label={resource.label}
                checked={resource.checked}
                name={resource.name}
                onChange={handleResourceCheckboxChange}
                disabled={isPdf}
              />
            ))}
          </Box>
        </Box>
        <Box>
          <Checkbox
            label="Filtrar pacientes por profissional"
            data-testid="filterPatientsByPhysicians"
            checked={selectedFilterPatientsByPhysicians}
            name="filterPatientsByPhysicians"
            onChange={handleSelectedFilterPatientsByPhysiciansChange}
            disabled={isPdf}
          />
        </Box>
        <Box>
          {!exportHasPhysician() && (
            <Box mb={1}>
              <Alert
                severity="warning"
                icon={<ReportProblemOutlined />}
                message="Nenhum profissional foi selecionado."
              />
            </Box>
          )}
          {!exportHasValidDates(
            selectedPeriod,
            selectedStartPeriod,
            selectedEndPeriod,
          ) && (
            <Box mb={1}>
              <Alert
                severity="warning"
                icon={<ReportProblemOutlined />}
                message="O intervalo de datas selecionado não é válido."
              />
            </Box>
          )}
          {!exportHasResource() && (
            <Box mb={1}>
              <Alert
                severity="warning"
                icon={<ReportProblemOutlined />}
                message="Nenhum tipo de dado foi selecionado."
              />
            </Box>
          )}
          {selectedFileFormat === 'xlsx' && (
            <Box mb={1}>
              <Alert
                severity="warning"
                icon={<ReportProblemOutlined />}
                message="O formato de arquivo XLSX possui limitações e pode resultar em perda de dados."
              />
            </Box>
          )}
          {selectedFileFormat === 'pdf' && (
            <Box mb={1}>
              <Alert
                severity="warning"
                icon={<ReportProblemOutlined />}
                message="A exportação PDF estará disponível em até 24h."
              />
            </Box>
          )}
          {duplicatedRequest && (
            <Box mb={1}>
              <Alert
                data-testid="duplicated-export-warning"
                severity="warning"
                icon={<ReportProblemOutlined />}
                message="Uma exportação com essas opções já foi solicitada num curto espaço de tempo."
              />
            </Box>
          )}
        </Box>
      </Box>
      <Box className={classes.optionBox}>
        <Button
          color="transparent"
          className={classes.backButton}
          startIcon={<MaterialIcons.ArrowBack />}
          onClick={() => dispatch(setExportDrawer(false))}
        >
          <Typography variant="overline">Voltar</Typography>
        </Button>
        {newRequestStatus === RequestStatus.Pending && (
          <Box m={4}>
            <Loader data-testid="loading" />
          </Box>
        )}
        {(newRequestStatus === null ||
          newRequestStatus === RequestStatus.Success) && (
          <Button onClick={requestNewDataExport} disabled={!exportIsValid()}>
            Exportar dados
          </Button>
        )}
      </Box>
    </Box>
  );
}
