import React from 'react';
import { Box, Heading, MaterialIcons, Button } from '@iclinic/design-system';

import useStyles from './dialogStyles.style';
import { FormStepContainerProps } from '../types';

const FormStepContainer = ({
  onClose,
  showCloseButton,
  title,
  children,
}: FormStepContainerProps) => {
  const classes = useStyles();

  return (
    <Box display="flex" flexDirection="column" mb={3}>
      <Box
        mb={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Heading variant="md">{title}</Heading>
        {showCloseButton && onClose && (
          <Button
            color="transparent"
            onClick={onClose}
            className={classes.exitButton}
          >
            <MaterialIcons.Close />
          </Button>
        )}
      </Box>

      {children}
    </Box>
  );
};

export default FormStepContainer;
