import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Terms from '../Terms';
import { ScreenType } from '../../state/types';
import {
  getSelectedScreenState,
  getSidebarIsopenState,
} from '../../state/selector';
import { actions } from '../../state';
import Loading from '../Loading';
import Summary from '../Summary';
import IntegrationError from '../IntegrationError';
import SidebarAI from 'features/records/components/SidebarAI';

const { triggerSidebar } = actions;

export type SidebarProps = {
  onClickTry: () => Promise<void>;
  isLoading: boolean;
};

const Sidebar = ({ onClickTry, isLoading }: SidebarProps) => {
  const selectedScreenState = useSelector(getSelectedScreenState);
  const isOpen = useSelector(getSidebarIsopenState);
  const dispatch = useDispatch();

  const content: Record<ScreenType, React.ReactNode> = {
    terms: <Terms />,
    loading: <Loading />,
    summary: <Summary />,
    integrationError: (
      <IntegrationError onClickTry={onClickTry} isLoading={isLoading} />
    ),
  };

  return (
    <SidebarAI
      isOpen={isOpen}
      title="Prontuário otimizado"
      dataGA={selectedScreenState === 'terms' ? 'resumoai_term_voltar' : ''}
      onClickBack={() => dispatch(triggerSidebar(false))}
    >
      {content[selectedScreenState]}
    </SidebarAI>
  );
};

export default Sidebar;
