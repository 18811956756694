import React from 'react';
import { FormikErrors, FormikTouched } from 'formik';
import {
  Box,
  MaterialUICore,
  MaterialIcons,
  InputAdornment,
} from '@iclinic/design-system';

import useStyles from './dialogStyles.style';
import { ClinicFormValues } from '../types';
import CustomTextField from './CustomTextField';

const { CircularProgress } = MaterialUICore;
const { SearchOutlined } = MaterialIcons;

const zipCodeAdornment = (isLoading: boolean) => ({
  endAdornment: (
    <InputAdornment position="end">
      {isLoading ? <CircularProgress size={14} /> : <SearchOutlined />}
    </InputAdornment>
  ),
});

interface ClinicFormFieldsProps {
  values: ClinicFormValues;
  errors: FormikErrors<ClinicFormValues>;
  touched: FormikTouched<ClinicFormValues>;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  formDisabled: boolean;
  zipCode: string;
  handleChangeZipCode: (e: React.ChangeEvent<HTMLInputElement>) => void;
  zipCodeIsLoading: boolean;
  clinicCnpj: string;
  handleChangeCnpj: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const ClinicFormFields = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  formDisabled,
  zipCode,
  handleChangeZipCode,
  zipCodeIsLoading,
  clinicCnpj,
  handleChangeCnpj,
}: ClinicFormFieldsProps) => {
  const classes = useStyles();
  const handleChangeZipCodeAdapter = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    handleChangeZipCode(e);
  };
  const handleChangeAdapter = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(e);
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Box flexBasis="48%">
          <CustomTextField
            label="CEP"
            id="zip_code"
            name="zip_code"
            type="text"
            placeholder="Ex: 14096-700"
            autoComplete="zip_code"
            margin="normal"
            error={!!(touched.zip_code && errors.zip_code)}
            helperText={touched.zip_code && errors.zip_code}
            onChange={handleChangeZipCodeAdapter}
            onBlur={handleBlur}
            value={zipCode}
            disabled={formDisabled}
            InputProps={zipCodeAdornment(zipCodeIsLoading)}
            dataGa="cfmupdate-zip_code"
          />
        </Box>
        <Box flexBasis="48%">
          <CustomTextField
            label="CNPJ da clínica"
            id="clinic_cnpj"
            name="clinic_cnpj"
            type="text"
            placeholder="Ex: 36.024.831/0001-40"
            autoComplete="cnpj"
            margin="normal"
            error={!!(touched.clinic_cnpj && errors.clinic_cnpj)}
            helperText={touched.clinic_cnpj && errors.clinic_cnpj}
            onChange={handleChangeCnpj}
            onBlur={handleBlur}
            value={clinicCnpj}
            disabled={formDisabled}
            dataGa="cfmupdate-clinic_cnpj"
            fullWidth
          />
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <CustomTextField
          label="Endereço"
          id="address"
          name="address"
          type="text"
          placeholder="Ex: Rua Professora Maria Aparecida"
          autoComplete="address"
          margin="normal"
          error={!!(touched.address && errors.address)}
          helperText={touched.address && errors.address}
          onChange={handleChangeAdapter}
          onBlur={handleBlur}
          value={values.address}
          disabled={formDisabled}
          dataGa="cfmupdate-address"
          fullWidth
          className={classes.fieldMargin}
        />
        <CustomTextField
          label="Número"
          id="number"
          name="number"
          type="text"
          placeholder="Ex: 515"
          autoComplete="number"
          margin="normal"
          error={!!(touched.number && errors.number)}
          helperText={touched.number && errors.number}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.number}
          disabled={formDisabled}
          dataGa="cfmupdate-number"
        />
      </Box>
      <Box display="flex" justifyContent="space-between">
        <CustomTextField
          label="Complemento"
          id="complement"
          name="complement"
          type="text"
          placeholder="Ex: Apartamento 5"
          autoComplete="complement"
          margin="normal"
          error={!!(touched.complement && errors.complement)}
          helperText={touched.complement && errors.complement}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.complement}
          disabled={formDisabled}
          dataGa="cfmupdate-complement"
          fullWidth
          className={classes.fieldMargin}
        />
        <CustomTextField
          label="Bairro"
          id="neighborhood"
          name="neighborhood"
          type="text"
          placeholder="Ex: Nova Ribeirânia"
          autoComplete="neighborhood"
          margin="normal"
          error={!!(touched.neighborhood && errors.neighborhood)}
          helperText={touched.neighborhood && errors.neighborhood}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.neighborhood}
          disabled={formDisabled}
          dataGa="cfmupdate-neighborhood"
          fullWidth
        />
      </Box>
    </>
  );
};

export default ClinicFormFields;
