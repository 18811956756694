import { Body, MaterialUICore, axiomTokens } from '@iclinic/design-system';

const {
  Drawer: DSDrawer,
  IconButton,
  styled,
  Checkbox,
  ListItem: DSListItem,
  ListItemIcon: DSListItemIcon,
} = MaterialUICore;
const { spacing, border, color, font } = axiomTokens;

export const Drawer = styled(DSDrawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    borderLeft: `${border.width.xs} solid ${color.border.subtle}`,
    width: '100%',

    [theme.breakpoints.up('sm')]: {
      width: 385,
    },
  },
}));

export const Content = styled('div')(() => ({
  padding: `${spacing[4]} ${spacing[4]} 0`,
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
}));

export const Header = styled('div')(() => ({
  marginBottom: spacing[1],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontSize: font.size.xs,
  fontWeight: font.weight.bold,
  lineHeight: font.lineHeight.xs,
}));

export const Description = styled(Body)(() => ({
  fontSize: font.size.xs,
  letterSpacing: font.letterSpacing.sm,
  maxWidth: '90%',
  marginBottom: spacing[4],
}));

export const ChipContainer = styled('div')({
  padding: 0,
  flexGrow: 1,
  overflowY: 'auto',
  display: 'flex',
  flexWrap: 'wrap',
  gap: spacing['2'],
});

export const SelectPharmasWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

export const SearchFieldWrapper = styled('div')({
  width: '100%',
  padding: 0,
});

export const ListWrapper = styled('div')({
  overflowY: 'auto',
});

export const ListItemIcon = styled(DSListItemIcon)({
  minWidth: spacing[5],
});

export const ListItem = styled(DSListItem)({
  padding: `0 ${spacing[1]}`,
});

export const CheckboxListItem = styled(Checkbox)({
  color: color.border.accent,

  '& .MuiCheckbox-colorSecondary.Mui-checked': {
    color: color.interactive.accent.default,
  },
});

export const FooterWrapper = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  backgroundColor: color.background[1],
  justifyContent: 'flex-end',
  gap: spacing[4],
  padding: spacing[4],
}));

export const CloseIconButton = styled(IconButton)({
  alignSelf: 'flex-start',
});

export const iconWrapper = styled('div')({
  height: spacing[6],
  width: spacing[6],

  '& svg': {
    width: '100%',
    height: '100%',
    fill: 'currentColor',
  },
});
