import onlinePaymentImages from 'features/onlinePayment/images';
import videoConferenceImages from 'features/video-conference/images';
import referralsImages from 'features/referrals/images';
import subscriptionImages from 'features/subscription/images';
import freeSamplesImages from 'features/free-samples/images';
import tissInvoicingImages from 'features/tissInvoicing/images';
import surveyImages from 'features/survey/images';
import signupImages from 'features/new-auth/signup/stepSignup/images';
import messagingImages from 'features/messaging/images';

const { ASSETS_PATH } = process.env;

const images = {
  ...onlinePaymentImages,
  ...videoConferenceImages,
  ...referralsImages,
  ...subscriptionImages,
  ...freeSamplesImages,
  ...tissInvoicingImages,
  ...surveyImages,
  ...signupImages,
  ...messagingImages,
  logosBank: {
    brasil: `${ASSETS_PATH!}images/bank-flags/001.svg`,
    santander: `${ASSETS_PATH!}images/bank-flags/033.svg`,
    itau: `${ASSETS_PATH!}images/bank-flags/341.svg`,
    nubank: `${ASSETS_PATH!}images/bank-flags/260.svg`,
    inter: `${ASSETS_PATH!}images/bank-flags/077.svg`,
    bancoob: `${ASSETS_PATH!}images/bank-flags/756.svg`,
    bradesco: `${ASSETS_PATH!}images/bank-flags/237.svg`,
    caixa: `${ASSETS_PATH!}images/bank-flags/104.svg`,
  },
  cards: {
    card: `${ASSETS_PATH!}images/card-flags/card.svg`,
    aura: `${ASSETS_PATH!}images/card-flags/aura.svg`,
    amex: `${ASSETS_PATH!}images/card-flags/amex.svg`,
    diners: `${ASSETS_PATH!}images/card-flags/dinners.svg`,
    discover: `${ASSETS_PATH!}images/card-flags/discover.svg`,
    elo: `${ASSETS_PATH!}images/card-flags/elo.svg`,
    hipercard: `${ASSETS_PATH!}images/card-flags/hipercard.svg`,
    mastercard: `${ASSETS_PATH!}images/card-flags/mastercard.svg`,
    visa: `${ASSETS_PATH!}images/card-flags/visa.svg`,
  },
  whiteCards: {
    card: `${ASSETS_PATH!}images/card-flags/card-white.svg`,
    aura: `${ASSETS_PATH!}images/card-flags/aura-white.svg`,
    amex: `${ASSETS_PATH!}images/card-flags/amex-white.svg`,
    diners: `${ASSETS_PATH!}images/card-flags/dinners-white.svg`,
    discover: `${ASSETS_PATH!}images/card-flags/discover-white.svg`,
    elo: `${ASSETS_PATH!}images/card-flags/elo-white.svg`,
    hipercard: `${ASSETS_PATH!}images/card-flags/hipercard-white.svg`,
    mastercard: `${ASSETS_PATH!}images/card-flags/mastercard-white.svg`,
    visa: `${ASSETS_PATH!}images/card-flags/visa-white.svg`,
  },
  boleto: {
    logo: `${ASSETS_PATH!}images/boleto/boleto.svg`,
  },
  campaign: {
    editorButtonActive: `${ASSETS_PATH!}images/campaign/editor/option-button-active.png`,
    editorButton: `${ASSETS_PATH!}images/campaign/editor/option-button.png`,
  },
  adminMessage: `${ASSETS_PATH!}images/message/admin-message.png`,
  adminMessageSucess: `${ASSETS_PATH!}images/message/admin-message-success.svg`,
  successImage: `${ASSETS_PATH!}images/common/upgrade/upgrade_success.gif`,
  sadIclinico: `${ASSETS_PATH!}images/common/dr_iclinico.png`,
  marketing: {
    illustrationRescue: `${ASSETS_PATH!}images/marketing/illustration-prize-to-rescue.svg`,
    illustrationRescued: `${ASSETS_PATH!}images/marketing/illustration-prize-rescued.svg`,
  },
  digitalSignature: {
    successImage: `${ASSETS_PATH!}images/digital-signature/success-image.png`,
  },
  settings: {
    integrationSuccess: `${ASSETS_PATH!}images/settings/integration-success.svg`,
    professionalCartoon: `${ASSETS_PATH!}images/settings/professional-cartoon.svg`,
  },
  cfmUpdate: {
    start: `${ASSETS_PATH!}images/cfmupdate/cfmupdate_start.svg`,
    done: `${ASSETS_PATH!}images/cfmupdate/cfmupdate_done.svg`,
  },
  records: {
    calculatorEmpty: `${ASSETS_PATH!}images/records/calculator-empty.svg`,
    prescriptionError: `${ASSETS_PATH!}images/records/prescription-error.svg`,
    hyperaBannerBox: `${ASSETS_PATH!}images/records/hypera-banner-box.png`,
  },
  schedule: {
    knowedThePep: `${ASSETS_PATH!}images/schedule/conheca-o-pep.jpg`,
  },
  downloadDataImages: {
    background: `${ASSETS_PATH!}images/banner-download-data/background-banner.png`,
    backgroundIllustration: `${ASSETS_PATH!}images/banner-download-data/background-illustration.png`,
    boxText: `${ASSETS_PATH!}images/banner-download-data/box-text.png`,
  },
  common: {
    dataMigrationBanner: `${ASSETS_PATH!}images/common/data-migration-banner.svg`,
  },
} as const;

export default images;
