import * as Yup from 'yup';

import { onlyNumbers } from 'shared/utils/formatters';
import { isValidCPF } from 'shared/utils/validation';
import { CBO } from 'features/tuss/cbo/types';

const required = 'Campo obrigatório';

export const professionalValidationSchema = Yup.object().shape({
  cpf: Yup.string()
    .required(required)
    .test(
      'isValidCPF',
      'CPF inválido',
      (value) => (value && isValidCPF(onlyNumbers(value))) || false,
    ),
  professional_cnpj: Yup.string().when('not_has_cnpj', {
    is: false,
    then: Yup.string()
      .required(required)
      .matches(/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/, 'Insira um CNPJ válido'),
    otherwise: Yup.string().nullable(),
  }),
  cbo: Yup.string().required(required),
  rqe: Yup.string(),
  council_name: Yup.string().required(required),
  council_region: Yup.string().required(required),
  council_number: Yup.string().required(required),
  not_has_cnpj: Yup.boolean(),
});

export const clinicValidationSchema = Yup.object().shape({
  zip_code: Yup.string()
    .required(required)
    .matches(/^\d{5}-\d{3}$/, 'Insira um CEP válido'),
  clinic_cnpj: Yup.string().when('not_has_cnpj', {
    is: false,
    then: Yup.string()
      .required(required)
      .matches(/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/, 'Insira um CNPJ válido'),
    otherwise: Yup.string().nullable(),
  }),
  address: Yup.string().required(required),
  number: Yup.string().required(required),
  complement: Yup.string(),
  neighborhood: Yup.string().required(required),
  city: Yup.string().required(required),
  state: Yup.string().required(required),
  not_has_cnpj: Yup.boolean(),
});

export const createProfessionalValidation = (cboList: CBO[]) =>
  professionalValidationSchema.concat(
    Yup.object().shape({
      cbo: Yup.string()
        .required(required)
        .test(
          'check-cbo',
          'Escolha uma especialidade da lista',
          (value) => !!cboList.find((item) => item.term === value),
        ),
    }),
  );
