import React, { useEffect } from 'react';
import { Route, Redirect, RouteComponentProps } from 'react-router-dom';
import { captureException } from '@sentry/browser';

import EmailStepContainer from '../containers/EmailStepContainer';
import PersonalStepContainer from '../containers/PersonalStepContainer';
import JobStepContainer from '../containers/ProfessionStepContainer';
import ProfessionalCountStepContainer from '../containers/ProfessionalCountStepContainer';
import SpecialtyStepContainer from '../containers/SpecialtyStepContainer';
import StepSignupContainer from './Signup';
import { loadRecaptcha } from '../services';

export default ({ match }: RouteComponentProps) => {
  useEffect(() => {
    loadRecaptcha({ autoHideBadge: true }).catch((e) => captureException(e));
  }, []);

  const renderRedirect = () => (
    <Redirect
      to={{
        pathname: `${match.path}/email`,
        search: window.location.search,
      }}
    />
  );

  return (
    <StepSignupContainer>
      <Route
        exact
        path={`${match.path}/email`}
        component={EmailStepContainer}
      />
      <Route
        exact
        path={`${match.path}/personal`}
        component={PersonalStepContainer}
      />
      <Route exact path={`${match.path}/job`} component={JobStepContainer} />
      <Route
        exact
        path={`${match.path}/professional-count`}
        component={ProfessionalCountStepContainer}
      />
      <Route
        exact
        path={`${match.path}/specialty`}
        component={SpecialtyStepContainer}
      />
      <Route render={renderRedirect} />
    </StepSignupContainer>
  );
};
