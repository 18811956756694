import * as Yup from 'yup';

import {
  required,
  validEmail,
  passwordValidation,
  invalidPassword,
} from 'features/new-auth/validationSchema';

export function validateBrPhone(value: string) {
  let isValid = false;
  isValid = /^\([1-9]{2}\) [2-9]\d{3,4}-\d{4}$/.test(value);

  if (value !== undefined && isValid) {
    const validators = [
      (phoneNumber: string) => !/^(\d)\1+$/.test(phoneNumber),
      (phoneNumber: string) => !/^(.)\1*$/.test(phoneNumber),
    ];

    const phoneNumber = value.replace(/\W/g, '');
    isValid = validators.every((validator) => validator(phoneNumber));
  }

  return isValid;
}

export const emailFormValidation = Yup.object({
  email: Yup.string().trim().required(required).email(validEmail),
  password: Yup.string()
    .required(required)
    .test(
      'password-validation',
      invalidPassword,
      (value) =>
        typeof value === 'string' && passwordValidation(value).isValidPassword,
    ),
});

export const personalFormValidation = Yup.object({
  name: Yup.string().trim().required(required),
  billingPhone: Yup.string()
    .required(required)
    .test(
      'br-phone',
      'Telefone inválido',
      (value) => typeof value === 'string' && validateBrPhone(value),
    ),
});

export const specialtyFormValidation = Yup.object({
  cbo: Yup.string().trim().required(required),
});
