import React from 'react';
import { Box, Heading, Body, Button } from '@iclinic/design-system';

import { UpdateStepProps } from '../types';
import { image } from 'shared/utils/urlGetter';

const DoneStep = ({ onClose }: UpdateStepProps) => (
  <>
    <Box alignItems="center" display="flex" flexDirection="column" mb={3}>
      <img
        src={image('cfmUpdate.done')}
        alt="Conclusão da atualização da regulamentação do CFM"
      />
      <Box mb={1}>
        <Heading variant="md">Dados atualizados!</Heading>
      </Box>

      <Body variant="sm" align="center">
        Obrigado, agora você já pode usar o sistema.
      </Body>
    </Box>

    <Box display="flex" justifyContent="right">
      <Button onClick={onClose}>Concluir</Button>
    </Box>
  </>
);

export default DoneStep;
