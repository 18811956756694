import { AxiosError } from 'axios';
import { ApiErrors } from '@types';

import { Profession, SignupSteps } from './state/types';
import { getUrlParams, getMultipleUrlParams } from 'shared/utils/url';
import { getCookie } from 'shared/utils/cookie';
import { defaultErrorCode } from './constants';

interface EmailError {
  errors: ApiErrors[];
}

export const getNextStepFromJobByProfession = (
  isVariant: boolean,
  profession: Profession,
) => {
  if (profession === Profession.Doctor && !isVariant) {
    return SignupSteps.Specialty;
  }

  return SignupSteps.ProfessionCount;
};

export const getPreviousStepFromSpecialtyByProfession = (
  isVariant: boolean = false,
  profession: Profession = Profession.Doctor,
) => {
  if (
    profession === Profession.AnotherHealthProfessional ||
    (profession === Profession.Doctor && isVariant)
  ) {
    return SignupSteps.ProfessionCount;
  }

  return SignupSteps.Job;
};

export const getCheckoutParam = () => getUrlParams('checkout');

export const getReferralCookieValue = (name: string) => {
  const referralsCookie = getCookie('iclinic_referrals');
  return referralsCookie?.split(`${name}=`).pop()?.split('|').shift() ?? '';
};

export const getReferralCookieParams = (...names: string[]) =>
  names.map((name) => getReferralCookieValue(name));

export const getReferralParams = () => {
  const referralParamNames = ['promo', 'referralCode', 'refSource'];
  const urlParams = getMultipleUrlParams(...referralParamNames);
  const cookieParams = getReferralCookieParams(...referralParamNames);
  const allValuesAreEmpty = urlParams.every((param) => param === '');
  return allValuesAreEmpty ? cookieParams : urlParams;
};

export const getErrorCode = (e: AxiosError<EmailError>): string =>
  e?.response?.data?.errors?.[0]?.code ?? e.message ?? defaultErrorCode;
export default getErrorCode;

export const getUtmsData = () => ({
  mkt__utms: getCookie('__utms'),
});
