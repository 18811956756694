import {
  Body as DSBody,
  axiomTokens,
  MaterialUICore,
  Card as DSCard,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;

const { font, color, spacing, shadow, border } = axiomTokens;

export const CardContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  gap: spacing[3],
  marginTop: spacing[3],
}));

export const Card = styled(DSCard)(({ theme }) => ({
  color: color.highlight.blue[1],
  backgroundColor: color.interactive.subtle.default,
  padding: spacing[4],
  textAlign: 'center',
  fontFamily: font.family.base,
  fontWeight: font.weight.bold,
  fontSize: font.size.xs,
  boxShadow: shadow.level[2],
  borderRadius: border.radius.md,

  [theme.breakpoints.down('sm')]: {
    marginTop: spacing[2],
  },
}));

export const Body = styled(DSBody)(() => ({
  fontSize: font.size.xs,
  lineHeight: '1.5',
  padding: `${spacing[10]} ${spacing[5]}`,
}));

export const BodyDescription = styled(DSBody)(() => ({
  textAlign: 'center',
  fontSize: font.size.xxxs,
  backgroundColor: color.surface.accent.default,
  padding: `${spacing[2]} ${spacing[3]}`,
  borderRadius: border.radius.md,
}));

export const IconWrapper = styled('div')({
  textAlign: 'center',
  height: spacing[8],
  width: '100%',
  marginBottom: spacing[3],

  '& svg': {
    width: '100%',
    height: '100%',
    fill: 'currentColor',
  },
});
