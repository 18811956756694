import { RequestStatus } from 'shared/constants/State';

export enum SignupSteps {
  Email = 1,
  Personal,
  Job,
  ProfessionCount,
  Specialty,
}

export enum Profession {
  Doctor = 'Médico',
  Rececptionist = 'Recepcionista',
  ClinicAdmin = 'Administrador(a) da clínica',
  BusinessITConsultant = 'Consultor(a) de TI/Negócios',
  AnotherHealthProfessional = 'Outro profissional de saúde',
  Patient = 'Paciente',
}

export enum ProfessionalCount {
  One = '1',
  TwoToFour = '2 a 4',
  FiveToTen = '5 a 10',
  TenToTwenty = '11 a 20',
  MoreThanTwenty = 'Mais que 20',
}

export const ProfessionFilters = new Map<string, string>([
  [Profession.Doctor, 'md'],
  [Profession.AnotherHealthProfessional, 'ot'],
]);

export const SignupStepsPaths = {
  [SignupSteps.Email]: '/email',
  [SignupSteps.Personal]: '/personal',
  [SignupSteps.Job]: '/job',
  [SignupSteps.ProfessionCount]: '/professional-count',
  [SignupSteps.Specialty]: '/specialty',
} as const;

export type SignupState = {
  progressStep: SignupSteps;
  email: string;
  status: RequestStatus;
  error: string;
  profession?: Profession;
  professionalCount?: ProfessionalCount;
  name?: string;
  billingPhone?: string;
  password?: string;
  cbo?: string;
  listCBO: CBOList[];
  isVariant: boolean;
  autoLogin: string;
};

export type CheckStepPayload = {
  progressStep: SignupSteps;
  navigationStep: SignupSteps;
};

export type EmailStepValues = {
  email: string;
  password: string;
};

export type PersonalStepValues = {
  name: string;
  billingPhone: string;
};

export type JobStepValues = {
  job: string;
};

export type SpecialtyStepValues = {
  cbo: string;
};

export function getOption(option: SearchCBOList | string | null): string {
  if (typeof option === 'string') return option;
  return option?.inputValue ?? option?.term ?? '';
}

export interface CBOList {
  term: string;
  cbo: number;
}
export interface SearchCBOList extends CBOList {
  inputValue?: string;
}

export type AutocompleteCboList = string | string[] | null;
export type StoreStatePayload = {
  autoLogin?: string;
};

export interface SignupFormValues {
  email: string;
  name: string;
  billing_phone: string;
  profession: string;
  cbo: string;
  professionals_count: string;
  password: string;
  termsOfServiceAndPrivacyPolicy: boolean;
}
