import {
  createAction,
  createSlice,
  PayloadAction,
  combineReducers,
} from '@reduxjs/toolkit';

import {
  CFMUpdatePayload,
  CFMUpdateState,
  CFMUpdateSteps,
  ClinicFormValues,
  ProfessionalFormValues,
} from '../types';
import { RequestStatus } from 'shared/constants/State';
import { zipCodeReducer } from '../services';

export const getCFMUpdateData = createAction('CFMUpdate/getData');

export const initialState: CFMUpdateState = {
  clinic: null,
  professional: null,
  step: CFMUpdateSteps.none,
  status: RequestStatus.Ready,
  clinicUpdateDone: false,
  professionalUpdateDone: false,
  error: '',
};

export const submitProfessional = createAction<ProfessionalFormValues>(
  'CFMUpdate/submitProfessional',
);

export const submitClinic = createAction<ClinicFormValues>(
  'CFMUpdate/submitClinic',
);

const cfmUpdateSlice = createSlice({
  name: 'cfmUpdate',
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<CFMUpdatePayload>) => {
      const { clinic, professional } = action.payload;
      return clinic || professional
        ? { ...state, clinic, professional, step: CFMUpdateSteps.start }
        : state;
    },
    nextStep: (state) => {
      const {
        step,
        professional,
        clinic,
        professionalUpdateDone,
        clinicUpdateDone,
      } = state;

      let next =
        professional && !professionalUpdateDone
          ? CFMUpdateSteps.professional
          : step;

      next = clinic && !clinicUpdateDone ? CFMUpdateSteps.clinic : next;

      return next !== step ? { ...state, step: next } : state;
    },

    professionalUpdatedSuccess: (state) => ({
      ...state,
      professionalUpdateDone: true,
      step: CFMUpdateSteps.done,
      status: RequestStatus.Success,
      error: '',
    }),

    dataUpdateError: (state, action: PayloadAction<string>) => ({
      ...state,
      status: RequestStatus.Error,
      error: action.payload,
    }),
    close: (state) => ({ ...state, step: CFMUpdateSteps.close }),
    requestPending: (state) => ({
      ...state,
      status: RequestStatus.Pending,
      error: '',
    }),
    clinicUpdatedSuccess: (state) => {
      const step = state.professional
        ? CFMUpdateSteps.professional
        : CFMUpdateSteps.done;

      return {
        ...state,
        clinicUpdateDone: true,
        step,
        status: RequestStatus.Success,
        error: '',
      };
    },
  },
});

export const {
  setData,
  nextStep,
  professionalUpdatedSuccess,
  close,
  requestPending,
  dataUpdateError,
  clinicUpdatedSuccess,
} = cfmUpdateSlice.actions;

const reducer = combineReducers({
  main: cfmUpdateSlice.reducer,
  zipCode: zipCodeReducer,
});

export const { reducer: mainReducer } = cfmUpdateSlice;
export default reducer;
